export class ColdCallingSpecialistCandidateInterview {
    constructor(details = {}) {
      this.englishFluency = this.parseInteger(details.english_fluency);
      this.englishPrononcuation = this.parseInteger(details.english_prononcuation);
      this.englishComprehension = this.parseInteger(details.english_comprehension);
      this.englishVocab = this.parseInteger(details.english_vocab);
      this.englishGrammar = this.parseInteger(details.english_grammar);
      this.typingSpeed = this.parseInteger(details.typing_speed);
      this.typingAccuracy = this.parseInteger(details.typing_accuracy);
    }
  
    parseInteger(value) {
      const parsed = parseInt(value, 10);
      return isNaN(parsed) ? null : parsed;
    }
  }
  