export default {
  install(Vue) {
    Vue.prototype.$Constants = {
      CANDIDATE_TYPES: [
        'ColdCallingSpecialistCandidate',
      // Add other candidate types as needed
      ],
      candidates: {
        CANDIDATES_TYPE_OPTIONS: [
          {
            title: "Cold Calling Specialist Candidate",
            value: "ColdCallingSpecialistCandidate"
          } // This has to be the first.
        ],
        VN_EVALUATION_OPTIONS: [
          {
            title: "Pending",
            value: "pending"
          },
          {
            title: "Accepted",
            value: "accepted"
          },
          {
            title: "Rejected",
            value: "rejected"
          }
        ],
        IS_CONTACTED_OPTIONS: [
          {
            title: "Yes",
            value: true
          },
          {
            title: "No",
            value: false
          }
        ],
        INTERVIEW_HIRING_DECISION_OPTIONS: [
          {
            title: "Pending",
            value: 0
          },
          {
            title: "Accepted",
            value: 1
          },
          {
            title: "Rejected",
            value: 2
          }
        ],
        MILITARY_STATUS_OPTIONS: [
          "Exempted",
          "Yet to be adhered to",
          "Postponed",
          "Not required"
        ],
        EDUCATIONAL_STATUS_OPTIONS: [
          "Graduate",
          "Drop out",
          "Under-graduate",
          "Gap year"
        ],
        CANDIDATE_SOURCES: {
          hrDepartment: {
            title: 'HR Department',
            value: 'hr_department'
          },
          referral: {
            title: 'Referral',
            value: 'referral'
          },
          externalSource: {
            title: "External Source",
            value: "external_source"
          },
          other: {
            title: 'Other',
            value: 'other'
          },
        },
        ONBOARDED_OPTIONS: [
          {
            title: "Pending",
            value: "pending"
          },
          {
            title: "Yes",
            value: "yes"
          },
          {
            title: "No",
            value: "no"
          }
        ],
      },
      interviews: {
        // When adding a new interview type option, make sure to include appropriate key-value pairs in the 
        // INTERVIEW_TO_CANDIDATE_TYPE and TABLE_HEADERS constants.
        INTERVIEW_TYPES_OPTIONS: [
          {
            title: "Cold Calling Specialist Interviews",
            value: "ColdCallingSpecialistCandidateInterview"
          }
        ],
        // Map the interview model name to its corresponding candidate model name in the backend so the filters
        // in the interviewees page work properly.
        INTERVIEW_TO_CANDIDATE_TYPE: {
          ColdCallingSpecialistCandidateInterview: "ColdCallingSpecialistCandidate"
        },
        // Needed by the interviewees page and passed interviews page to display specific interview evaluations for
        // different candidate types.
        TABLE_HEADERS: {
          ColdCallingSpecialistCandidateInterview: [
            { text: "English Fluency", value: "interview.englishFluency", sortable: false },
            { text: "English Pronunciation", value: "interview.englishPrononcuation", sortable: false },
            { text: "English Comprehension", value: "interview.englishComprehension", sortable: false },
            { text: "English Vocab", value: "interview.englishVocab", sortable: false },
            { text: "English Grammar", value: "interview.englishGrammar", sortable: false },
            { text: "Typing Speed", value: "interview.typingSpeed", sortable: false },
            { text: "Typing Accuracy", value: "interview.typingAccuracy", sortable: false },
          ]
        }
      },
      baseAttributes: [
        "interview_date",
        "hiring_decision",
        "ticket_number",
        "onboarded",
        "notes",
      ],
    };
  }
};