import Vue from 'vue';
import Vuex from 'vuex'; 
import VueAxios from 'vue-axios';
import axios from '../plugins/axios';
import offices from './modules/offices';
import menus from './modules/menus';
import leaves from './modules/leaves';
import users from './modules/users';
import leaveReuests from './modules/leaveRequests';
import sessions from './modules/sessions';
import targets from './modules/targets';
import teams from './modules/teams';
import actions from './modules/actions';
import holidays from './modules/holidays';
import leads from './modules/leads';
import dialers from './modules/dialers';
import departments from './modules/departments';
import salaries from './modules/salaries';
import candidates from './modules/candidates';
import interviews from './modules/interviews';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    loading: false,
    currentPermission: '',
    username: 'nouran',
    password: '221123#'
  },
  getters: {
    loading: (state) => state.loading,
    currentPermission: (state) => state.currentPermission,
    getUsername: (state) => state.username,
    getPassword: (state) => state.password
  },
  mutations: {
    setLoading: (state, isLoading) => (state.loading = isLoading),
    setCurrentPermission: (state, currentPermission) => (state.currentPermission = currentPermission)
  },
  actions: {
    setLoading({commit}, isLoading) {
      commit('setLoading', isLoading);
    },
    setPermission({commit}, currentPermission) {
      commit('setCurrentPermission', currentPermission);
    }
  },
  modules: {
    offices,
    menus,
    leaves,
    users,
    leaveReuests,
    sessions,
    targets,
    teams,
    actions,
    holidays,
    leads,
    dialers,
    departments,
    salaries,
    candidates,
    interviews
  }
});
