import { Candidate } from "@/models/candidate";
import { InterviewData } from "@/models/interview";
import { List, ListParams } from "@/models/list";
import Vue from 'vue';

const state = {
  interviews: new List(),
  candidateInterviews: new List(),
  selectedInterview: null
};

const getters = {
  interviews: (state) => state.interviews,
  candidateInterviews: (state) => state.candidateInterviews,
  selectedInterview: (state) => state.selectedInterview,
};

const actions = {
  async createInterview({ commit }, { candidate_id, data }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post(
        `/candidates/${candidate_id}/interviews`,
        data
      );
      if (response.status === 201) {
        commit("addCandidateInterview", response.data.data);
        this.$app.$snotify.success("Interview has been successfully added.");
      } else {
        throw new Error("Failed to add interview");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while adding the interview, please try again"
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchLatestAcceptedInterviews({ commit }, data) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get("/interviews/latest_accepted_interviews", {
        params: new ListParams(data),
      });
      if (response.status === 200) {
        commit("setLatestAcceptedInterviews", response.data);
        this.$app.$snotify.success("Latest passed interviews data fetched successfully.");
      } else {
        throw new Error("Failed to fetch latest passed interviews data");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while fetching latest passed interviews data."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async trackForOnboarding({ commit }, interview_ids) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.patch(
        "/interviews/track_for_onboarding",
        {interview_ids}
      );
      if (response.status === 200) {
        this.$app.$snotify.success("Candidate(s) are tracked for onboarded successfully.");
      } else {
        throw new Error("Failed to track candidate(s) for onboarding");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while tracking candidate(s) for onboarding."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchCandidateInterviews({ commit }, {candidate_id}) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(
        `/candidates/${candidate_id}/interviews`
      );
      if (response.status === 200) {
        commit("setCandidateInterviews", response.data.data);
      } else {
        throw new Error("Failed to fetch candidate interviews");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while fetching candidate interviews, please try again"
      );
    } finally {
      commit("setLoading", false);
    }
  },
   // Fetch a single interview (for update or view)
   async fetchInterview({ commit }, { candidate_id, interview_id }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(
        `/candidates/${candidate_id}/interviews/${interview_id}`
      );
      if (response.status === 200) {
        commit("setSelectedInterview", response.data.data);
      } else {
        throw new Error("Failed to fetch the interview.");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the interview, please try again."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async updateInterview({ commit }, { candidate_id, interview_id, data }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.patch(
        `/candidates/${candidate_id}/interviews/${interview_id}`,
        data
      );
      if (response.status === 200) {
        this.$app.$snotify.success("Interview updated successfully.");
      } else {
        throw new Error("Failed to update interview.");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while updating the interview, please try again."
      );
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setLatestAcceptedInterviews: (state, data) => {
    state.interviews = new List({
      items: data.items.map(
        (entry) => ({
          candidate: new Candidate(entry.candidate),
          interview: new InterviewData(entry.interview, Vue.prototype.$Constants)
        })
      ),
      count: data.extra.total_count
    })
  },
  // Set all interviews for a candidate
  setCandidateInterviews: (state, candidateInterviews) => {
    state.candidateInterviews = new List({
      items: candidateInterviews.map((item) =>
        new InterviewData(item, Vue.prototype.$Constants)
      ),
      count: candidateInterviews.length,
    });
  },

  // Set selected interview
  setSelectedInterview: (state, interview) => {
    state.selectedInterview = new InterviewData(interview, Vue.prototype.$Constants);
  },

  // Add a new interview to the list
  addCandidateInterview: (state, interview) => {
    state.candidateInterviews.items.push(new InterviewData(interview, Vue.prototype.$Constants));
    state.candidateInterviews.count++;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};