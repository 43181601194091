/**
* A class that can return the Candidate model
*/
export class Candidate {
  /** @param {Object} candidate FastJson object. */
  constructor(candidate = {}) {
    // Model Fields.
    this.id = candidate.id || null;
    this.full_name = candidate.attributes.full_name || null;
    this.national_id = candidate.attributes.national_id || "N/A";
    this.contact_number = candidate.attributes.contact_number || "N/A";
    this.contact_email = candidate.attributes.contact_email || "N/A";
    this.nationality = candidate.attributes.nationality || "N/A";
    this.city_of_residence = candidate.attributes.city_of_residence || "N/A";
    this.military_status = candidate.attributes.military_status || "N/A";
    this.educational_status = candidate.attributes.educational_status || "N/A";
    this.is_contacted = candidate.attributes.is_contacted || false;
    this.vn_evaluation_feedback = candidate.attributes.vn_evaluation_feedback || "N/A";
    this.type = candidate.attributes.type;
    this.external_source = candidate.attributes.external_source || null;

    if (candidate.attributes.sourced_by) {
      this.sourcer_id = candidate.attributes.sourced_by.res_id;
      this.sourcer_name = candidate.attributes.sourced_by.name;
    } else {
      this.sourcer_id = null;
      this.sourcer_name = "Referral";
    }

    if (candidate.attributes.referred_by) {
      this.referrer_id = candidate.attributes.referred_by.res_id;
      this.referrer_name = candidate.attributes.referred_by.name;
    } else {
      this.referrer_id = null;
      this.referrer_name = null;
    }

    // Fields used by the UI.
    if (candidate.attributes.sourced_by) {
      this.source_name = this.sourcer_name;
    } else if (candidate.attributes.referred_by) {
      this.source_name = `Referral ${this.referrer_id}`;
    } else {
      this.source_name = this.external_source;
    }
  }    
}