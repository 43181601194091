import { ColdCallingSpecialistCandidateInterview } from "@/models/coldCallingSpecialistCandidateInterview";

export class InterviewData {
  /**
   * @param {Object} interviewData FastJson object.
   * @param {Object} Constants Reference to global constants, if needed for mapping
   */
  constructor(interviewData = {}, Constants) {
    this.id = interviewData.id;
    this.interviewDate = (interviewData.interview_date ?? interviewData.attributes?.interview_date )|| "N/A";
    this.hiringDecision = this.getHiringDecision(
      (interviewData.hiring_decision ?? interviewData.attributes?.hiring_decision),
      Constants
    );
    this.ticketNumber = (interviewData.ticket_number ?? interviewData.attributes?.ticket_number) || "";
    this.onboarded = this.getOnboardedDecision(
      (interviewData.onboarded ?? interviewData.attributes?.onboarded),
      Constants
    );
    this.notes = (interviewData.notes ?? interviewData.attributes?.notes) || "";

    // Handle interview type specific details

    const interviewTypeSpecific = (interviewData.interview_type_specific ?? interviewData.attributes?.interview_type_specific) || {};
    const interviewTypeKey = Object.keys(
      interviewTypeSpecific
    )[0];
    this.interviewType = interviewTypeKey || "";
    const specificDetailsInstance = this.getSpecificDetails(
      interviewTypeKey,
      interviewTypeSpecific[Object.keys(interviewTypeSpecific)[0]] // Access using the key
    );
   

    if (specificDetailsInstance) {
      for (const [key, value] of Object.entries(specificDetailsInstance)) {
        this[key] = value;
      }
    }
  }

  /**
   * Maps hiring decision code to a readable title using constants.
   * @param {number} decisionCode
   * @param {object} Constants - Global constants for mapping decision code to title
   * @returns {string} The title of the hiring decision.
   */
  getHiringDecision(decisionCode, Constants) {
    const decisionOption =
      Constants.candidates.INTERVIEW_HIRING_DECISION_OPTIONS[decisionCode];
    return decisionOption ? decisionOption.title : "Pending";
  }

  getOnboardedDecision(decisionCode, Constants) {
    const decisionOption =
      Constants.candidates.ONBOARDED_OPTIONS[decisionCode];
    return decisionOption ? decisionOption.title : null;
  }

  /**
   * Returns specific details model instance based on interview type.
   * @param {string} typeKey The key of the interview type.
   * @param {object} details The specific details data.
   * @returns {object} The interview type-specific model instance.
   */
  getSpecificDetails(typeKey, details) {
    switch (typeKey) {
      case "ColdCallingSpecialistCandidateInterview":
        return new ColdCallingSpecialistCandidateInterview(details);
      // Add other cases for other interview types as needed
      default:
        return null;
    }
  }
}
