export default {
  apperance: 'Apperance',
  dark: 'Dark Mode',
  light: 'Light Mode',
  constants: {
    signIn: 'Sign in',
    register: 'Registration',
    theme: 'Theme Settings',
    updatePassword: 'Please enter a new password',
    resetPassword: {
      click: 'Forgot Password? Click',
      here: 'here',
      reset: 'to reset your password.',
      enterEmail: 'Enter email to reset your password'
    },
    toContinue: 'to continue',
    company_name: 'Pharos Solutions',
    copyright: '©Copyright {currentYear}, Pharos Solutions. All Rights Reserved.',
    registrationComplete: 'You have registered your company profile successfully! We have sent you a confirmation email',
    fieldPreSelected: 'This file was pre-selected: ',
    noProject: 'No Project'
  },
  users: {
    invitation: 'Invite a new user',
    contactInfo: 'Contact info',
    workInfo: 'Work Info',
    permission: 'Permission',
    searchKey: 'Search key',
    searchValue: 'Search value',
    attributes: {
      name: 'Name',
      id: 'Emp ID',
      department: 'Department',
      job_title: 'Job Title',
      email: 'Email',
      work_phone: 'Work Phone'
    }
  },
  offices: {
    newOffice: 'New Office',
    updateOffice: 'Update Office'
  },
  kudos: {
    newKudo: 'New Kudo',
    updateKudo: 'Update Kudo',
    new: 'New',
    edit: 'Edit'
  },
  assets: {
    newAsset: 'New Asset',
    updateAsset: 'Update Asset'
  },
  teams: {
    newTeam: 'New Team',
    updateTeam: 'Update Team'
  },
  leaves: {
    new: 'New',
    edit: 'Edit',
    newLeave: 'New Leave',
    updateLeave: 'Update Leave',
    updateTotalBalance: 'Update Total Balance',
    cancelReason: 'Cancellation Reason',
    inactiveUser: 'Inactive User'
  },
  departments: {
    newDepartment: 'New Department',
    updateDepartment: 'Update Department'
  },
  documents: {
    newDocument: 'New Document'
  },
  holidays: {
    newHoliday: 'New Holiday',
    updateHoliday: 'Update Holiday'
  },
  goals: {
    updateGoal: 'Update Goal',
    newGoal: 'New Goal',
    new: 'New',
    edit: 'Edit',
    goalDetails: 'Goal Details'
  },
  helpDesks: {
    updateHelpDesk: 'Update Help Desk',
    newHelpDesk: 'New Help Desk',
    new: 'New',
    edit: 'Edit'
  },
  candidates: {
    candidateDetails: 'Candidate Details',
    updateCandidate: 'Update Candidate',
    candidateMissingData: 'Candidate Missing Data'
  },
  interviews: {
    addInterview: 'Add Interview',
    updateInterview: 'Update Interview',
    viewInterview: 'view Interview'
  }
};
